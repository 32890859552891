.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-info {
  margin-top: -0.2rem;
  border-radius: .2rem;
  background: #FFF;
  padding: .4rem;
  position: relative;
  z-index: 1;
}
.page-info .title {
  font-size: 0.48rem;
  font-weight: bold;
  color: #333;
}
.page-info .title2 {
  font-size: 0.37333333rem;
  color: #a0a4a3;
  margin-top: .2rem;
}
.page-info .other {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  height: 1rem;
  padding-top: .3rem;
  margin-top: .3rem;
}
.page-info .price {
  height: 1rem;
  line-height: 1rem;
}
.page-info .price span {
  font-size: 0.53333333rem;
  color: #fea130;
}
.page-info .price em {
  font-size: 0.37333333rem;
  color: #999;
  text-decoration: line-through;
}
.page-info .hits {
  height: 1rem;
  line-height: 1rem;
}
.page-info .hits span {
  font-size: 0.37333333rem;
  color: #333;
  margin: 0 .1rem;
}
.page-sticky {
  margin-top: .24rem;
}
.page-sticky .content {
  display: flex;
  width: 10rem;
  margin: 0 auto;
  height: 1.1rem;
  box-sizing: border-box;
  padding-top: .1rem;
  border-bottom: .02rem solid #eaeaea;
  background: #FFF;
}
.page-sticky .content a {
  flex: 1;
}
.page-sticky .content a span {
  font-size: 0.42666667rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  position: relative;
}
.page-sticky .content a span:before {
  content: '';
  display: none;
  width: .6rem;
  height: .06rem;
  background: #5ec2b5;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -0.3rem;
  border-radius: .12rem;
}
.page-sticky .content a span i {
  display: inline-block;
  font-size: 0.37333333rem;
  position: relative;
  top: -0.08rem;
  left: .04rem;
}
.page-sticky .content a.active span::before {
  display: block;
}
.page-content {
  padding: .4rem;
  background: #FFF;
}
.page-content p {
  font-size: 0.42666667rem;
}
.page-comment {
  margin-top: .4rem;
  background: #FFF;
  padding-bottom: .4rem;
}
.page-comment .hd {
  display: flex;
  align-items: center;
  padding: .7rem .4rem .4rem;
  width: 100%;
  justify-content: space-between;
}
.page-comment .hd span {
  font-size: 0.42666667rem;
  font-weight: 600;
  color: #333;
}
.page-comment .loop .item {
  display: flex;
  padding: .4rem;
}
.page-comment .loop .item .pic {
  position: relative;
  overflow: hidden;
  border-radius: .14rem .14rem 0 0;
  width: .9rem;
  height: .9rem;
  border-radius: 50%;
  z-index: 10;
}
.page-comment .loop .item .pic:before {
  content: '';
  display: block;
  padding-bottom: 2.4rem;
}
.page-comment .loop .item .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  border-radius: 50%;
}
.page-comment .loop .item .info {
  flex: 1;
  overflow: hidden;
  margin-left: .4rem;
}
.page-comment .loop .item .info .name {
  font-size: 0.42666667rem;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: .2rem;
}
.page-comment .loop .item .info .content {
  font-size: 0.37333333rem;
  color: #66;
  line-height: .66rem;
}
.page-recommend {
  margin-top: .4rem;
  background: #FFF;
  padding-bottom: .4rem;
}
.page-recommend .hd {
  display: flex;
  align-items: center;
  padding: .7rem .4rem .4rem;
  width: 100%;
  justify-content: space-between;
}
.page-recommend .hd span {
  font-size: 0.42666667rem;
  font-weight: 600;
  color: #333;
}
.page-recommend .hd a {
  font-size: 0.32rem;
  color: #a0a4a3;
}
.page-recommend .loop {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 .28rem;
}
.page-recommend .item {
  padding: 0 .12rem;
  width: 50%;
  box-sizing: border-box;
}
.page-recommend a {
  display: flex;
  flex-direction: column;
}
.page-recommend a .pic {
  position: relative;
  overflow: hidden;
  border-radius: .14rem .14rem 0 0;
}
.page-recommend a .pic:before {
  content: '';
  display: block;
  padding-bottom: 2.4rem;
}
.page-recommend a .pic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
}
.page-recommend a .info {
  border-top: none;
  padding: .22rem .1rem .1rem;
}
.page-recommend a .info .title {
  font-size: 0.37333333rem;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-recommend a .info .hits {
  font-size: 0.32rem;
  color: #a0a4a3;
}
.page-control {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.page-control .content {
  width: 10rem;
  margin: 0 auto;
  display: flex;
  background: #FFF;
  height: 1.44rem;
  box-sizing: border-box;
  padding: .14rem .2rem;
  border-top: .02rem solid #f3f3f3;
}
.page-control .content .btn {
  display: flex;
  width: 1.32rem;
  height: 1.16rem;
  flex-direction: column;
  font-size: 0.26666667rem;
  color: #333;
  align-items: center;
  justify-content: center;
}
.page-control .content .btn i {
  font-size: 0.69333333rem;
  height: .7rem;
}
.page-control .content .submit {
  display: block;
  height: 1.16rem;
  color: #FFF;
  font-size: 0.37333333rem;
  background: #fea130;
  flex: 1;
  border-radius: .14rem;
  margin-left: .2rem;
  font-size: 0.42666667rem;
  text-align: center;
}
.page-control .content .submit i {
  font-size: 0.37333333rem;
  line-height: 1.16rem;
}
.page-control .content .submit span {
  font-size: 0.42666667rem;
  margin: 0 .08rem;
  line-height: 1.16rem;
}
.page-control .content .submit.small {
  background: #5ec2b5;
}
.page-control .content .submit.s span {
  font-size: 0.37333333rem;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal .content {
  width: 7.8rem;
  padding: .4rem 0;
  border-radius: .16rem;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal .content img {
  width: 7rem;
  display: block;
}
.modal .content a {
  margin-top: .4rem;
  font-size: 0.37333333rem;
  color: #333;
  display: block;
  width: 3rem;
  text-align: center;
}
.ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.ticket .content {
  width: 7.8rem;
  padding: .4rem 0;
  border-radius: .16rem;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ticket .content p {
  font-size: 0.48rem;
  text-align: center;
  font-weight: 600;
  padding-top: .4rem;
}
.ticket .content .img {
  width: 4.6rem;
  display: block;
  padding: .2rem;
  box-sizing: border-box;
  border: .1rem solid #5ec2b5;
  margin: 1rem 0 .4rem;
  border-radius: .4rem;
}
.ticket .content .d {
  margin-top: .4rem;
  font-size: 0.42666667rem;
  color: #5ec2b5;
  display: block;
  text-align: center;
  padding-bottom: .4rem;
}
