.fs(@font-size) {
    font-size: @font-size / 37.5rem;
}

@info: #cdac7a;



















































































































.detal-html p,
.detal-html span,
.detal-html a {.fs(14)!important;line-height: .6rem!important;}
.detal-html section {width: 100%!important;zoom: 1;margin-left: 0!important;margin-right: 0!important;
    &:before,
    &:after         { content: ""; display: table; }
    &:after         { clear: both; }
}
.detal-html img {max-width: 100%!important;width: auto;display: inline-block;}
