.ellipsis {overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
.page               {
    &-banner        {}
    &-info          {margin-top: -.2rem;border-radius: .2rem;background: #FFF;padding: .4rem;position: relative;z-index: 1;
        .title      {.fs(18);font-weight: bold;color: #333;}
        .title2     {.fs(14);color: #a0a4a3;margin-top: .2rem;}
        .other      {display: flex;justify-content: space-between;width: 100%;border-top: 1px solid #f1f1f1;height: 1rem;padding-top: .3rem;margin-top: .3rem;}
        .price      {height: 1rem;line-height: 1rem;
            span    {.fs(20);color: #fea130;}
            em      {.fs(14);color: #999;text-decoration: line-through;}
        }
        .hits       {height: 1rem;line-height: 1rem;
            span    {.fs(14);color: #333;margin: 0 .1rem;}
        }
    }

    &-sticky                    {margin-top: .24rem;
        .content                {display: flex;width:10rem;margin: 0 auto;height: 1.1rem;box-sizing: border-box;padding-top: .1rem;border-bottom: .02rem solid #eaeaea;background: #FFF;
            a                   {flex: 1;
                span            {.fs(16);display: flex;justify-content: center;align-items: center;height: 1rem;position: relative;
                    &:before    {content: '';display: none;width: .6rem;height: .06rem;background: #5ec2b5;position: absolute;bottom: 0;left: 50%;margin-left: -.3rem;border-radius: .12rem;}
                    i           {display: inline-block;.fs(14);position: relative;top: -.08rem;left: .04rem;}
                }
                &.active span::before {display: block;}
            }
        }
    }

    &-main           {}
    &-content        {padding: .4rem;background: #FFF;
        p            {.fs(16);}
    }

    &-comment               {margin-top: .4rem;background: #FFF;padding-bottom: .4rem;
        .hd                 {display: flex;align-items: center;padding: .7rem .4rem .4rem;width: 100%;justify-content: space-between;
            span            {.fs(16);font-weight: 600;color: #333;}
        }
        .loop               {
            .item           {display: flex;padding: .4rem;
                .pic        {position: relative;overflow: hidden;border-radius: .14rem .14rem 0 0;width: .9rem;height: .9rem;border-radius: 50%;z-index: 10;
                    &:before{content: '';display: block;padding-bottom: 2.4rem;}
                    img     {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;border-radius: 50%;}
                }
                .info       {flex: 1;overflow: hidden;margin-left: .4rem;
                    .name   {.fs(16);font-weight: 600;color: #333;.ellipsis;margin-bottom: .2rem;}
                    .content{.fs(14);color: #66;line-height: .66rem;}
                }
            }
        }
    }

    &-recommend         {margin-top: .4rem;background: #FFF;padding-bottom: .4rem;
        .hd             {display: flex;align-items: center;padding: .7rem .4rem .4rem;width: 100%;justify-content: space-between;
            span        {.fs(16);font-weight: 600;color: #333;}
            a           {.fs(12);color: #a0a4a3;}
        }
        .loop           {display: flex;width: 100%;flex-wrap: wrap;padding: 0 .28rem;}
        .item           {padding: 0 .12rem;width: 50%;box-sizing: border-box;}
        a               {display: flex;flex-direction: column;
            .pic        {position: relative;overflow: hidden;border-radius: .14rem .14rem 0 0;
                &:before{content: '';display: block;padding-bottom: 2.4rem;}
                img     {position: absolute;top: 50%;left: 50%;transform: translate3d(-50%, -50%, 0);width: 100%;}
            }
            .info       {border-top: none;padding: .22rem .1rem .1rem;
                .title  {.fs(14);font-weight: 600;color: #333;.ellipsis;}
                .hits   {.fs(12);color: #a0a4a3;}
            }
        }
    }

    &-control           {position: fixed;bottom: 0;left: 0;width: 100%;z-index: 100;
        .content        {width: 10rem;margin: 0 auto;display: flex;background: #FFF;height: 1.44rem;box-sizing:border-box;padding: .14rem .2rem;border-top: .02rem solid #f3f3f3;
            .btn        {display: flex;width: 1.32rem;height: 1.16rem;flex-direction: column;.fs(10);color: #333;align-items: center;justify-content: center;
                i       {.fs(26);height: .7rem;}
            }
            .submit     {display: block;height: 1.16rem;color: #FFF;.fs(14);background: #fea130;flex: 1;border-radius: .14rem;margin-left: .2rem;.fs(16);text-align: center;
                i       {.fs(14);line-height: 1.16rem;}
                span    {.fs(16);margin: 0 .08rem;line-height: 1.16rem;}
                &.small {background: #5ec2b5;}
                &.s span{.fs(14);}
            }
        }
    }
}

.modal                  {display: flex;align-items: center;justify-content: center;height: 100%;
    .content            {width: 7.8rem;padding: .4rem 0;border-radius: .16rem;background: #FFF;display: flex;flex-direction: column;align-items: center;
        img             {width: 7rem;display: block;}
        a               {margin-top: .4rem;.fs(14);color: #333;display: block;width: 3rem;text-align: center;}
    }
}

.ticket                 {display: flex;align-items: center;justify-content: center;height: 100%;
    .content            {width: 7.8rem;padding: .4rem 0;border-radius: .16rem;background: #FFF;display: flex;flex-direction: column;align-items: center;
        p               {.fs(18);text-align: center;font-weight: 600;padding-top: .4rem;}
        .img            {width: 4.6rem;display: block;padding: .2rem;box-sizing: border-box;border: .1rem solid #5ec2b5;margin: 1rem 0 .4rem;border-radius: .4rem;}
        .d              {margin-top: .4rem;.fs(16);color: #5ec2b5;display: block;text-align: center;padding-bottom: .4rem;}
    }
}